<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <main>
      <div class="p-4">
        <div
          class="row align-items-center justify-content-center"
          style="margin-top: -180px"
        >
          <div class="col-md-6 bg-white p-4 rounded-5 shadow-lg box">
            <h4 class="text-center">Registrasi</h4>
            <hr />
            <div>
              <div v-if="showForm == '1'">
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>Nama Lengkap</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      v-model="form.nama_lengkap"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': formValidate.nama_lengkap,
                      }"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>NIM</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      v-model="form.nim"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': formValidate.nim,
                      }"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>No.HP/WA</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      v-model="form.telp"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': formValidate.telp,
                      }"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>Progam Studi</label>
                  </div>
                  <div class="col-md-8">
                    <select
                      class="form-select"
                      v-model="form.prodi"
                      :class="{
                        'is-invalid': formValidate.prodi,
                      }"
                    >
                      <option value="">Pilih Program Studi</option>
                      <option value="Sistem Informasi">Sistem Informasi</option>
                      <option value="Teknik Informatika">
                        Teknik Informatika
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>Foto</label>
                  </div>
                  <div class="col-md-8">
                    <a
                      href="#"
                      class="btn btn-block btn-primary m-1"
                      role="button"
                      @click.prevent="showFileChooser"
                      :class="{
                        'btn-danger': formValidate.foto,
                      }"
                    >
                      <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                    </a>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <input
                      style="display: none"
                      ref="input"
                      type="file"
                      name="image"
                      accept="image/*"
                      @change="setImage"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="2 / 3"
                        :src="imgSrc"
                        preview=".preview"
                        style="
                          width: 100%;
                          border-radius: 5px;
                          overflow: hidden;
                        "
                      />
                      <div v-if="this.imgSrc">
                        <div class="btn-group mt-1">
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="rotate(90)"
                          >
                            <font-awesome-icon icon="redo" />
                          </a>
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="rotate(-90)"
                          >
                            <i class="fa fa-redo fa-flip-horizontal"></i>
                            <font-awesome-icon icon="undo" />
                          </a>
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="cropImage"
                          >
                            <font-awesome-icon icon="crop" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <canvas ref="myCanvas" style="display: none"></canvas>
                    <img
                      v-if="cropImg"
                      :src="cropImg"
                      width="100%"
                      style="border-radius: 10px"
                      alt="KTM"
                    />
                  </div>
                  <small class="text-info">Foto Menggunakan Almamater</small>
                  <small class="text-info"
                    >- Sistem Informasi : Latar Kuning</small
                  >
                  <small class="text-info"
                    >- Teknik Informatika : Latar Merah</small
                  >
                </div>
              </div>
              <div v-if="showForm == '2'">
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>Nama Wali</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      v-model="form.nama_wali"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': formValidate.nama_wali,
                      }"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>Nomor Telp/WA Wali</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      v-model="form.telp_wali"
                      type="number"
                      class="form-control"
                      :class="{
                        'is-invalid': formValidate.telp_wali,
                      }"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>Riwayat Penyakit</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      v-model="form.riwayat_penyakit"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': formValidate.riwayat_penyakit,
                      }"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>Ukuran Baju</label>
                  </div>
                  <div class="col-md-8">
                    <select
                      class="form-select"
                      v-model="form.ukuran_baju"
                      :class="{
                        'is-invalid': formValidate.ukuran_baju,
                      }"
                    >
                      <option value="">Pilih Ukuran Baju</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <a
                    v-if="showForm == '1'"
                    href="javascript:window.history.go(-1);"
                    class="btn btn-secondary"
                  >
                    Kembali
                  </a>
                  <button
                    v-if="showForm == '2'"
                    @click="showForm = '1'"
                    class="btn btn-secondary"
                  >
                    Kembali
                  </button>
                  <button
                    v-if="showForm == '1'"
                    @click="cekBiodata()"
                    class="btn btn-primary float-end"
                    type="button"
                    :disabled="this.loading"
                  >
                    <font-awesome-icon
                      v-if="loading"
                      icon="circle-notch"
                      :spin="loading"
                    />
                    Selanjutnya
                  </button>
                  <button
                    v-if="showForm == '2'"
                    @click="submitForm()"
                    class="btn btn-primary float-end"
                    type="button"
                    :disabled="this.loading"
                  >
                    <font-awesome-icon
                      v-if="loading"
                      icon="circle-notch"
                      :spin="loading"
                    />
                    Daftar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    VueCropper,
  },
  data() {
    return {
      form: {
        nama_lengkap: "",
        nim: "",
        prodi: "",
        telp: "",
        foto: "",
        riwayat_penyakit: "",
        ukuran_baju: "",
        nama_wali: "",
        telp_wali: "",
      },
      showForm: 1,
      formValidate: [],
      loading: false,
      imgSrc: "",
      cropImg: "",
    };
  },
  // mounted() {
  //   this.drawCanvas();
  // },
  methods: {
    cekBiodata() {
      this.loading = true;
      const formData = new FormData();
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("nim", this.form.nim);
      formData.append("prodi", this.form.prodi);
      formData.append("telp", this.form.telp);
      formData.append("foto", this.cropImg);
      axios
        .post("/v1/registrasi/validasi/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.showForm = 2;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    submitForm() {
      this.loading = true;
      const formData = new FormData();
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("nim", this.form.nim);
      formData.append("prodi", this.form.prodi);
      formData.append("telp", this.form.telp);
      formData.append("riwayat_penyakit", this.form.riwayat_penyakit);
      formData.append("ukuran_baju", this.form.ukuran_baju);
      formData.append("nama_wali", this.form.nama_wali);
      formData.append("telp_wali", this.form.telp);
      formData.append("foto", this.cropImg);
      axios
        .post("/v1/registrasi/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: "Sukses",
              text: "Registrasi Sukses, Anda dapat login dengan menggunakan NIM Anda untuk Username dan Password ",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Saira";
  src: url("@/assets/saira.ttf") format("truetype");
}
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
