<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header bg-primary text-light">
            Detail Kegiatan Harian
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="img-box">
                  <div v-if="loading" class="skeleton-screen"></div>
                  <img
                    id="myImage"
                    :src="detail.dokumentasi"
                    alt="dokumentasi"
                    style="width: 100%; border-radius: 10px"
                    @load="contentLoaded"
                  />
                </div>
              </div>
              <div class="col-md-9">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <span v-if="detail.jam_masuk">Tanggal Kegiatan :</span>
                        <span v-else class="skeleton-label"></span>
                        <div>
                          <span v-if="detail.jam_masuk">
                            {{ formatTanggal(detail.tanggal_kegiatan) }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-if="detail.jam_masuk">Waktu :</span>
                        <span v-else class="skeleton-label"></span>
                        <div>
                          <span v-if="detail.jam_masuk">
                            {{ formatJam(detail.jam_masuk) }} -
                            {{ formatJam(detail.jam_pulang) }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-if="detail.kegiatan">Kegiatan :</span>
                        <span v-else class="skeleton-label"></span>
                        <div>
                          <span v-if="detail.kegiatan">
                            {{ detail.kegiatan }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-if="detail.deskripsi">Deskripsi :</span>
                        <span v-else class="skeleton-label"></span>
                        <div>
                          <span
                            v-if="detail.deskripsi"
                            v-html="detail.deskripsi"
                          ></span>
                          <span v-else class="skeleton-loader"></span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-if="detail.masalah">Permasalahan :</span>
                        <span v-else class="skeleton-label"></span>
                        <div>
                          <span
                            v-if="detail.masalah"
                            v-html="detail.masalah"
                          ></span>
                          <span v-else class="skeleton-loader"></span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-if="detail.solusi">Solusi :</span>
                        <span v-else class="skeleton-label"></span>
                        <div>
                          <span
                            v-if="detail.solusi"
                            v-html="detail.solusi"
                          ></span>
                          <span v-else class="skeleton-loader"></span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <a
              class="btn btn-sm btn-secondary"
              href="javascript:history.go(-1);"
            >
              Kembali
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "FormPage",
  data() {
    return {
      detail: {
        id_kegiatan: "",
        nim_mhs: "",
        tanggal: "",
        jam_masuk: "",
        jam_pulang: "",
        kegiatan: "",
        deskripsi: "",
        masalah: "",
        solusi: "",
        dokumentasi: "",
      },
      loading: false,
      key: "",
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.loading = true;
    const id = this.$route.params.id;
    this.key = id;
    this.fetchData();
  },
  methods: {
    contentLoaded() {
      this.loading = false;
    },
    fetchData() {
      axios
        .get("/v1/kegiatan/detail/" + this.key)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    formatTanggal(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    formatFull(dateTime) {
      return moment(dateTime).format("D MMMM YYYY, HH:mm");
    },
    formatJam(dateTime) {
      return moment(dateTime, "HH:mm:ss").format("HH:mm");
    },
  },
};
</script>
<style>
.alert {
  display: flex;
  align-items: center;
}
.img-box {
  width: 100%;
  height: 200px;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
.skeleton-screen {
  width: 100%;
  height: 200px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.skeleton-label {
  width: 50%;
  height: 20px;
  margin-left: 10px;
  margin-bottom: 5px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.skeleton-image {
  width: 100%;
  height: 100%;
  background-color: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;

  animation: shine 1s infinite;
}

.real-image {
  display: none; /* Sembunyikan gambar sebenarnya */
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>