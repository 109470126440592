<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-scroll fixed-top bg-white shadow-sm py-3"
    >
      <div class="container">
        <img
          src="../../assets/logo-text.png"
          alt="Logo"
          height="35"
          class="d-inline-block align-text-top my-2"
        />
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <font-awesome-icon icon="bars" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto">
            <li>
              <router-link
                class="nav-link"
                :class="{
                  open: link == 'home',
                }"
                to="/home"
                >HOME</router-link
              >
            </li>
            <li>
              <router-link
                class="nav-link"
                :class="{
                  open: link == 'pengumuman',
                }"
                to="/home"
                >PENGUMUMAN</router-link
              >
            </li>
            <!-- <router-link class="btn btn-primary ms-3" to="/login">
              LOGIN
            </router-link> -->
          </ul>
        </div>
      </div>
    </nav>
    <!-- Navbar -->
    <div
      class="d-flex align-items-center justify-content-center text-center"
      style="height: 280px"
    ></div>
    <div class="header align-items-center justify-content-center"></div>
  </div>
</template>
<script>
export default {
  name: "HeaderPage",

  data() {
    return {
      link: "home",
      subtitle: "",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        if (link == "formulir-1") {
          this.subtitle = "Pembuatan KTM ( Kartu Tanda Mahasiswa )";
        }
      },
    },
  },
};
</script>
<style scoped>
.nav-link {
  border-radius: 5px;
  padding: 0.65em;
  margin: 2px 5px;
  min-width: 150px;
  text-align: center;
}
.nav-link:hover {
  background-color: #bfdef3;
  color: #0099ff;
}
.open {
  background-color: #51b0f0;
  color: #fff;
  font-weight: 400;
  /* text-decoration: underline; */
  text-underline-position: under;
}
</style>