<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header bg-primary text-light">Kegiatan Harian</div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{
                enabled: true,
                placeholder: 'Cari',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                perPageDropdownEnabled: false,
                position: 'bottom',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
              }"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-sm btn-secondary"
                    style="margin-right: 5px"
                  >
                    <font-awesome-icon icon="sync" :spin="loading" />
                  </button>
                </div>
              </div>
              <template slot="table-column" slot-scope="props">
                <template v-if="props.column.field === 'action'">
                  <font-awesome-icon icon="ellipsis-h" />
                </template>
                <template v-else>
                  {{ props.column.label }}
                </template>
              </template>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'dibuat'">
                  {{ formatFull(props.row.created_at) }}
                </span>
                <span v-if="props.column.field == 'tgl_kegiatan'">
                  {{ formatTanggal(props.row.tanggal) }}
                </span>
                <span v-if="props.column.field == 'waktu'">
                  {{ formatJam(props.row.jam_mulai) }} -
                  {{ formatJam(props.row.jam_selesai) }}
                </span>
                <span v-if="props.column.field == 'action'">
                  <div>
                    <router-link
                      :to="
                        '/laporan-kegiatan-harian/detail/' +
                        props.row.id_kegiatan
                      "
                      class="btn btn-sm btn-success"
                      style="margin-right: 5px"
                    >
                      <font-awesome-icon icon="search" />
                    </router-link>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "IndexPage",
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "tgl_kegiatan",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "NIM",
          field: "nim_mhs",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Nama",
          field: "nama_mhs",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Kegiatan",
          field: "kegiatan",
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Masalah",
          field: "masalah",
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Solusi",
          field: "solusi",
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "50px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      openForm: false,
      userLevel: "user",
      userToken: "",
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
      this.userToken = this.user.token;
    }
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      //   this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/v1/kegiatan/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/kegiatan/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    formatTanggal(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    formatJam(dateTime) {
      return moment(dateTime, "HH:mm:ss").format("HH:mm");
    },
    formatFull(dateTime) {
      return moment(dateTime).format("D MMMM YYYY, HH:mm");
    },
  },
};
</script>