<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header bg-primary text-light">Profil</div>
          <div class="card-body">
            <div
              class="alert alert-info fade show f-s-13"
              style="margin-bottom: 15px"
            >
              <div class="row">
                <div class="col-md-3" style="min-width: 150px">
                  <div>
                    <div class="img-box">
                      <div v-if="loading" class="skeleton-screen"></div>
                      <img
                        id="myImage"
                        style="width: 100%"
                        :src="detail.pas_foto"
                        @load="contentLoaded"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6" style="padding-right: 30px">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <span v-if="detail.nama_lengkap">Nama :</span>
                        <span v-else class="skeleton-label"></span>
                        <p class="bold fs-6 mb-2">
                          <span v-if="detail.nama_lengkap">
                            {{ detail.nama_lengkap }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <span v-if="detail.nim">NIM :</span>
                        <span v-else class="skeleton-label"></span>
                        <p class="bold fs-6 mb-2">
                          <span v-if="detail.nim">
                            {{ detail.nim }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <span v-if="detail.program_studi">Program Studi :</span>
                        <span v-else class="skeleton-label"></span>
                        <p class="bold fs-6 mb-2">
                          <span v-if="detail.program_studi">
                            {{ detail.program_studi }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <span v-if="detail.no_handphone">Telp/WA :</span>
                        <span v-else class="skeleton-label"></span>
                        <p class="bold fs-6 mb-2">
                          <span v-if="detail.no_handphone">
                            {{ detail.no_handphone }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h6>Kelengkapan Berkas</h6>
            <div class="row">
              <div class="col-md-9">
                <div
                  class="alert alert-info fade show"
                  style="padding: 5px; margin-bottom: 5px"
                >
                  <span v-if="detail.slip_kklp" class="text-success">
                    <font-awesome-icon class="fs-3 mx-1" icon="check-square"
                  /></span>
                  <span v-else class="text-grey fa-lg">
                    <font-awesome-icon class="fs-3 mx-1" icon="minus-square"
                  /></span>
                  <span class="mx-2 fs-6"> Slip KKLP </span>
                  <a
                    v-if="detail.slip_kklp"
                    class="btn btn-sm btn-info"
                    :href="detail.slip_kklp"
                    target="_blank"
                    style="position: absolute; right: 3px"
                  >
                    <font-awesome-icon icon="file-alt"
                  /></a>
                </div>
              </div>
              <div class="col-md-3" style="padding: 5px 12px">
                <input
                  type="file"
                  @change="handleFileChange($event, 1)"
                  ref="fileInput1"
                  style="display: none"
                />
                <div class="row">
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="chooseFile(1)"
                    >
                      <font-awesome-icon icon="file-alt" /> Pilih File
                    </button>
                  </div>
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      v-if="selectedFile1"
                      @click="uploadFile(1)"
                    >
                      <font-awesome-icon
                        v-if="this.loading1"
                        icon="circle-notch"
                        :spin="this.loading1"
                      />
                      <font-awesome-icon v-if="!this.loading1" icon="upload" />
                      Unggah
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-1">
                <div class="progress" v-if="this.uploadProgress1 !== null">
                  <div
                    class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    :style="{ width: `${this.uploadProgress1}%` }"
                    :aria-valuenow="this.uploadProgress1"
                    aria-valuemin="0"
                    :aria-valuemax="this.uploadProgress1"
                  >
                    {{ this.uploadProgress1 }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div
                  class="alert alert-info fade show"
                  style="padding: 5px; margin-bottom: 5px"
                >
                  <span v-if="detail.slip_spp" class="text-success">
                    <font-awesome-icon class="fs-3 mx-1" icon="check-square"
                  /></span>
                  <span v-else class="text-grey fa-lg">
                    <font-awesome-icon class="fs-3 mx-1" icon="minus-square"
                  /></span>
                  <span class="mx-2 fs-6">
                    Slip SPP Semester 1 - 6 & Pembangunan
                  </span>
                  <a
                    v-if="detail.slip_spp"
                    class="btn btn-sm btn-info"
                    :href="detail.slip_spp"
                    target="_blank"
                    style="position: absolute; right: 3px"
                  >
                    <font-awesome-icon icon="file-alt"
                  /></a>
                </div>
                <small class="text-info">Jadikan 1 Dokumen PDF</small>
              </div>
              <div class="col-md-3" style="padding: 5px 12px">
                <input
                  type="file"
                  @change="handleFileChange($event, 2)"
                  ref="fileInput2"
                  style="display: none"
                />
                <div class="row">
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="chooseFile(2)"
                    >
                      <font-awesome-icon icon="file-alt" /> Pilih File
                    </button>
                  </div>
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      v-if="selectedFile2"
                      @click="uploadFile(2)"
                    >
                      <font-awesome-icon
                        v-if="this.loading2"
                        icon="circle-notch"
                        :spin="this.loading2"
                      />
                      <font-awesome-icon v-if="!this.loading2" icon="upload" />
                      Unggah
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-1">
                <div class="progress" v-if="this.uploadProgress2 !== null">
                  <div
                    class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    :style="{ width: `${this.uploadProgress2}%` }"
                    :aria-valuenow="this.uploadProgress2"
                    aria-valuemin="0"
                    :aria-valuemax="this.uploadProgress2"
                  >
                    {{ this.uploadProgress2 }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div
                  class="alert alert-info fade show"
                  style="padding: 5px; margin-bottom: 5px"
                >
                  <span v-if="detail.krs_aktif" class="text-success">
                    <font-awesome-icon class="fs-3 mx-1" icon="check-square"
                  /></span>
                  <span v-else class="text-grey fa-lg">
                    <font-awesome-icon class="fs-3 mx-1" icon="minus-square"
                  /></span>
                  <span class="mx-2 fs-6"> KRS Semester 1 - 6 </span>
                  <a
                    v-if="detail.krs_aktif"
                    class="btn btn-sm btn-info"
                    :href="detail.krs_aktif"
                    target="_blank"
                    style="position: absolute; right: 3px"
                  >
                    <font-awesome-icon icon="file-alt"
                  /></a>
                </div>
                <small class="text-info">Jadikan 1 Dokumen PDF</small>
              </div>
              <div class="col-md-3" style="padding: 5px 12px">
                <input
                  type="file"
                  @change="handleFileChange($event, 3)"
                  ref="fileInput3"
                  style="display: none"
                />
                <div class="row">
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="chooseFile(3)"
                    >
                      <font-awesome-icon icon="file-alt" /> Pilih File
                    </button>
                  </div>
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      v-if="selectedFile3"
                      @click="uploadFile(3)"
                    >
                      <font-awesome-icon
                        v-if="this.loading3"
                        icon="circle-notch"
                        :spin="this.loading3"
                      />
                      <font-awesome-icon v-if="!this.loading3" icon="upload" />
                      Unggah
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-1">
                <div class="progress" v-if="this.uploadProgress3 !== null">
                  <div
                    class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    :style="{ width: `${this.uploadProgress3}%` }"
                    :aria-valuenow="this.uploadProgress3"
                    aria-valuemin="0"
                    :aria-valuemax="this.uploadProgress3"
                  >
                    {{ this.uploadProgress3 }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div
                  class="alert alert-info fade show"
                  style="padding: 5px; margin-bottom: 5px"
                >
                  <span v-if="detail.surat_pernyataan" class="text-success">
                    <font-awesome-icon class="fs-3 mx-1" icon="check-square"
                  /></span>
                  <span v-else class="text-grey fa-lg">
                    <font-awesome-icon class="fs-3 mx-1" icon="minus-square"
                  /></span>
                  <span class="mx-2 fs-6"> Surat Pernyataan </span>
                  <a
                    v-if="detail.surat_pernyataan"
                    class="btn btn-sm btn-info"
                    :href="detail.surat_pernyataan"
                    target="_blank"
                    style="position: absolute; right: 3px"
                  >
                    <font-awesome-icon icon="file-alt"
                  /></a>
                </div>
                <a href="javascript:;" @click="downloadPDF"
                  >Download Surat Pernyataan</a
                >
              </div>
              <div class="col-md-3" style="padding: 5px 12px">
                <input
                  type="file"
                  @change="handleFileChange($event, 4)"
                  ref="fileInput4"
                  style="display: none"
                />
                <div class="row">
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="chooseFile(4)"
                    >
                      <font-awesome-icon icon="file-alt" /> Pilih File
                    </button>
                  </div>
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      v-if="selectedFile4"
                      @click="uploadFile(4)"
                    >
                      <font-awesome-icon
                        v-if="this.loading4"
                        icon="circle-notch"
                        :spin="this.loading4"
                      />
                      <font-awesome-icon v-if="!this.loading4" icon="upload" />
                      Unggah
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-1">
                <div class="progress" v-if="this.uploadProgress4 !== null">
                  <div
                    class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    :style="{ width: `${this.uploadProgress4}%` }"
                    :aria-valuenow="this.uploadProgress4"
                    aria-valuemin="0"
                    :aria-valuemax="this.uploadProgress4"
                  >
                    {{ this.uploadProgress4 }}%
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div
                  class="alert alert-info fade show"
                  style="padding: 5px; margin-bottom: 5px"
                >
                  <span v-if="detail.sertifikat" class="text-success">
                    <font-awesome-icon class="fs-3 mx-1" icon="check-square"
                  /></span>
                  <span v-else class="text-grey fa-lg">
                    <font-awesome-icon class="fs-3 mx-1" icon="minus-square"
                  /></span>
                  <span class="mx-2 fs-6"> Sertifikat Lainnya </span>
                  <a
                    v-if="detail.sertifikat"
                    class="btn btn-sm btn-info"
                    :href="detail.sertifikat"
                    target="_blank"
                    style="position: absolute; right: 3px"
                  >
                    <font-awesome-icon icon="file-alt"
                  /></a>
                </div>
                <small class="text-info">Upload Sertifikat Jika ada</small>
              </div>
              <div class="col-md-3" style="padding: 5px 12px">
                <input
                  type="file"
                  @change="handleFileChange($event, 5)"
                  ref="fileInput5"
                  style="display: none"
                />
                <div class="row">
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="chooseFile(5)"
                    >
                      <font-awesome-icon icon="file-alt" /> Pilih File
                    </button>
                  </div>
                  <div class="col d-grid">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      v-if="selectedFile5"
                      @click="uploadFile(5)"
                    >
                      <font-awesome-icon
                        v-if="this.loading5"
                        icon="circle-notch"
                        :spin="this.loading5"
                      />
                      <font-awesome-icon v-if="!this.loading5" icon="upload" />
                      Unggah
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-1">
                <div class="progress" v-if="this.uploadProgress5 !== null">
                  <div
                    class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    :style="{ width: `${this.uploadProgress5}%` }"
                    :aria-valuenow="this.uploadProgress5"
                    aria-valuemin="0"
                    :aria-valuemax="this.uploadProgress5"
                  >
                    {{ this.uploadProgress5 }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <a
              href="javascript:window.history.go(-1);"
              class="btn btn-sm btn-secondary"
              >Kembali</a
            >
          </div>
        </div>
      </div>

      <!-- <div ref="pdfContent"> -->
      <div ref="pdfContent" style="display: none">
        <div class="text-center">
          <h5>Surat Pernyataan</h5>
        </div>
        <div style="margin-top: 50px">
          <p>Saya yang bertanda tangan di bawah ini :</p>
          <table style="width: 100%">
            <tr>
              <td style="width: 250px">Nama</td>
              <td style="width: 10px">:</td>
              <td>{{ detail.nama_lengkap }}</td>
            </tr>
            <tr>
              <td>NIM</td>
              <td>:</td>
              <td>{{ detail.nim }}</td>
            </tr>
            <tr>
              <td>Program Studi</td>
              <td>:</td>
              <td>{{ detail.program_studi }}</td>
            </tr>
            <tr>
              <td>Nomor HP</td>
              <td>:</td>
              <td>{{ detail.no_handphone }}</td>
            </tr>
          </table>
        </div>
        <p style="margin-top: 15px">Dengan ini menyatakan :</p>
        <ol style="text-align: justify">
          <li>
            Bersedia mengikuti kegiatan KKLP sesuai ketentuan yang ditetapkan
            oleh penyelenggara, STMIK Adhi Guna dan Mitra.
          </li>
          <li>
            Keikutsertaan saya dalam kegiatan KKLP telah memperoleh izin dan
            persetujuan orang tua.
          </li>
          <li>
            Bersedia mentaati segala bentuk peraturan yang ditetapkan oleh
            penyelenggara maupun mitra.
          </li>
          <li>
            Menerima sanksi bila saya melanggar tata tertib KKLP baik oleh
            penyelenggara maupun mitra.
          </li>
        </ol>
        <p>Demikian surat pernyataan ini saya buat dengan sebenarnya.</p>
        <table style="width: 100%">
          <tr>
            <td>
              <span>Menyetujui</span>
              <br />
              <span>Orang Tua Mahasiswa</span>
              <br />
              <br />
              <br />
              <span>Meterai & TTD</span>
              <br />
              <br />
              <br />
              <span>{{ detail.nama_wali }}</span>
            </td>
            <td class="text-center">
              <br />
              <br />
              <span>Mahasiswa</span>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <span>{{ detail.nama_lengkap }}</span>
              <br />
              <span>{{ detail.nim }}</span>
            </td>
          </tr>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import html2pdf from "html2pdf.js";

export default {
  name: "IndexPage",
  data() {
    return {
      detail: {
        id_mahasiswa: "",
        nama_lengkap: "",
        nim: "",
        program_studi: "",
        no_handphone: "",
        riwayat_penyakit: "",
        ukuran_baju: "",
        nama_wali: "",
        telp_wali: "",
        slip_kklp: "",
        slip_spp: "",
        krs_aktif: "",
        surat_pernyataan: "",
        sertifikat: "",
        pas_foto: "",
        created_by: "",
      },
      formValidate: [],
      actionBtn: "Simpan",
      loading1: false,
      selectedFile1: null,
      uploadProgress1: null,
      loading2: false,
      selectedFile2: null,
      uploadProgress2: null,
      loading3: false,
      selectedFile3: null,
      uploadProgress3: null,
      loading4: false,
      selectedFile4: null,
      uploadProgress4: null,
      loading5: false,
      selectedFile5: null,
      uploadProgress5: null,

      loading: false,
      openForm: false,
      userLevel: "user",
      userToken: "",
      response: "",
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
      this.userToken = this.user.token;
    }
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.loading = true;
    const id = this.$route.params.id;
    if (id) {
      this.detail.id_mahasiswa = id;
      this.fetchData(this.detail.id_mahasiswa);
    }
  },
  methods: {
    contentLoaded() {
      this.loading = false;
    },
    fetchData(id) {
      //   this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/v1/mahasiswa/detail/" + id, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/mahasiswa/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    formatTanggal(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    formatJam(dateTime) {
      return moment(dateTime, "HH:mm:ss").format("HH:mm");
    },
    formatFull(dateTime) {
      return moment(dateTime).format("D MMMM YYYY, HH:mm");
    },

    chooseFile(param) {
      this.$refs["fileInput" + param].click();
    },
    handleFileChange(event, param) {
      const file = event.target.files[0];
      if (file) {
        this["selectedFile" + param] = file;
      }
    },
    uploadFile(param) {
      this["loading" + param] = true;

      const formData = new FormData();
      formData.append("file", this["selectedFile" + param]);
      formData.append("id_mahasiswa", this.detail.id_mahasiswa);
      formData.append("type_file", param);
      if (param == "1") {
        formData.append("link_file", this.detail.slip_kklp);
      } else if (param == "2") {
        formData.append("link_file", this.detail.slip_spp);
      } else if (param == "3") {
        formData.append("link_file", this.detail.krs_aktif);
      } else if (param == "4") {
        formData.append("link_file", this.detail.surat_pernyataan);
      } else if (param == "5") {
        formData.append("link_file", this.detail.sertifikat);
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: this.userToken,
        },
        onUploadProgress: (progressEvent) => {
          this["uploadProgress" + param] = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      };

      axios
        .post("/v1/mahasiswa/upload", formData, config)
        .then((response) => {
          this.response = response;
          this["loading" + param] = false;
          this.fetchData(this.detail.id_mahasiswa);
          this["uploadProgress" + param] = null;
          this["selectedFile" + param] = null;
        })
        .catch((error) => {
          this.error = error.message;
          this["uploadProgress" + param] = null;
        });
    },
    setBerkas1(event) {
      const formData = new FormData();
      formData.append("id_raepe", this.detail.id_raepe);
      formData.append("ket_berkas", event.target.value);
      axios
        .post("/api/raepe/setberkas1", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          console.log(response.data.status);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    downloadPDF() {
      const contentHidden = this.$refs["pdfContent"];

      const content = contentHidden.innerHTML;
      var opt = {
        margin: 20,
        filename: "Surat Pernyataan.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
      };

      html2pdf().set(opt).from(content).save();
    },
  },
};
</script>
<style>
.alert {
  display: flex;
  align-items: center;
}
.img-box {
  width: 150px;
  height: 200px;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
.skeleton-screen {
  width: 150px;
  height: 200px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.skeleton-label {
  width: 50%;
  height: 20px;
  margin-left: 10px;
  margin-bottom: 5px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.skeleton-image {
  width: 100%;
  height: 100%;
  background-color: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;

  animation: shine 1s infinite;
}

.real-image {
  display: none; /* Sembunyikan gambar sebenarnya */
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>