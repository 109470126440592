<template>
  <div>
    <main>
      <div class="container-fluid px-lg-5">
        <h5 class="mt-4 mb-3">Dashboard</h5>
        <div class="alert alert-info" role="alert">
          <div class="row">
            <div class="col-md-7 p-4">
              <h2>
                Selamat Datang, <strong>{{ this.fullName }}</strong> !
              </h2>
              <p class="fs-6 mt-4">
                Selamat datang di Laman KKLP STMIK Adhi Guna, Yayasan Pendidikan
                dan Pembinaan Manajemen (YPPM) Adhi Guna. Laman ini
                diperuntukkan untuk mengelola data Kuliah Kerja Lapangan Profesi
                (KKLP) yang dilaksanakan oleh STMIK Adhi Guna.
              </p>
            </div>
            <div class="col-md-5 d-none d-sm-block">
              <img
                src="../assets/admin_header.png"
                style="height: 200px; float: right"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3 my-1">
            <router-link
              v-if="userLevel == 'mahasiswa'"
              :to="'/mahasiswa/profil/' + userId"
              class="btn w-100 btn-info"
            >
              <font-awesome-icon icon="user" /> Lengkapi Profil Saya
            </router-link>
          </div>
          <div class="col-12 col-md-3 my-1">
            <router-link
              v-if="userLevel == 'mahasiswa'"
              :to="'kegiatan-harian/create/'"
              class="btn w-100 btn-primary"
            >
              <font-awesome-icon icon="clipboard" /> Isi Kegiatan Harian
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "DashboardPage",
  data() {
    return {
      fullName: "",
      userName: "",
      userLevel: "",
      userId: "",
      loading: true,
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.fullName = this.user.nama;
      this.userName = this.user.username;
      this.userLevel = this.user.level;
      this.userId = this.user.id;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/dashboard")
        .then((response) => {
          for (let key in this.detail) {
            this.detail[key] = response.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>