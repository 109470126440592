<template>
  <div>
    <audio ref="shutter">
      <source src="../../assets/shutter.mp3" type="audio/mpeg" />
    </audio>
    <main>
      <div class="container-fluid px-4">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="card mb-4 mt-4">
            <div class="card-header bg-primary text-light">Kegiatan Harian</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="">Kegiatan</label>
                    <input
                      v-model="form.kegiatan"
                      type="text"
                      class="form-control mt-2"
                      :class="formValidate.kegiatan ? ' is-invalid' : ''"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.kegiatan"
                    ></div>
                  </div>
                </div>
                <div class="col-6 col-md-4 mt-2">
                  <div class="form-group">
                    <label for="">Jam Masuk</label>
                    <input
                      v-model="form.jam_masuk"
                      type="time"
                      class="form-control mt-2"
                      :class="formValidate.jam_masuk ? ' is-invalid' : ''"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-4 mt-2">
                  <div class="form-group">
                    <label for="">Jam Pulang</label>
                    <input
                      v-model="form.jam_pulang"
                      type="time"
                      class="form-control mt-2"
                      :class="formValidate.jam_pulang ? ' is-invalid' : ''"
                    />
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="">Deskripsi Kegiatan</label>
                    <div
                      class="editor-continer"
                      :class="formValidate.deskripsi ? ' border-danger' : ''"
                    >
                      <quill-editor
                        v-model="form.deskripsi"
                        :options="editorOption"
                        :style="editorStyles"
                      ></quill-editor>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="">Masalah</label>
                    <textarea
                      v-model="form.masalah"
                      class="form-control"
                      :class="formValidate.masalah ? ' is-invalid' : ''"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="">Solusi</label>
                    <textarea
                      v-model="form.solusi"
                      class="form-control"
                      :class="formValidate.solusi ? ' is-invalid' : ''"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="">Dokumentasi</label>
                    <div>
                      <input
                        style="display: none"
                        ref="input"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="setImage"
                      />
                      <div class="btn-group" role="group" v-if="!cameraTwoOpen">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          class="btn btn-sm btn-primary dropdown-toggle"
                          :class="formValidate.dokumentasi ? ' btn-danger' : ''"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <font-awesome-icon icon="file" /> Ambil Dokumentasi
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                        >
                          <li>
                            <a
                              class="dropdown-item"
                              href="javascript:;"
                              @click="showFileChooser"
                              ><span>
                                <font-awesome-icon icon="image" /> Buka
                                Gallery</span
                              ></a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="javascript:;"
                              @click="startCamera(2)"
                              ><span>
                                <font-awesome-icon icon="camera" /> Buka
                                Kamera</span
                              ></a
                            >
                          </li>
                        </ul>
                      </div>
                      <button
                        v-else
                        type="button"
                        class="btn btn-sm btn-block btn-dark"
                        @click="stopCamera(2)"
                      >
                        <span
                          ><font-awesome-icon icon="times" /> Tutup Kamera</span
                        >
                      </button>
                      <small
                        class="text-danger"
                        v-html="formValidate.dokumentasi"
                      ></small>
                    </div>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.solusi"
                    ></div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <canvas ref="canvasSketsa" style="display: none"></canvas>
                  <div
                    class="bg-primary"
                    v-show="kameraFotoSketsa"
                    style="border-radius: 10px; overflow: hidden; width: 100%"
                  >
                    <video
                      style="border-radius: 10px; overflow: hidden; width: 100%"
                      v-show="kameraFotoSketsa"
                      ref="kameraSketsa"
                      autoplay
                    ></video>
                    <div
                      v-if="cameraTwoOpen"
                      style="
                        position: absolute;
                        left: 0;
                        right: 0;
                        text-align: center;
                        margin-top: -70px;
                        width: 100%;
                        z-index: 999;
                      "
                    >
                      <button
                        @click="takePicture(2)"
                        type="button"
                        class="btn btn-light"
                        style="
                          border: solid 3px #0093dd;
                          width: 50px;
                          height: 50px;
                          line-height: 25px;
                          text-align: center;
                          color: #6c757d;
                          cursor: pointer;
                          font-size: 16px;
                          border-radius: 50%;
                          -webkit-border-radius: 50%;
                        "
                      >
                        <font-awesome-icon icon="camera" />
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div
                    v-if="hasilFotoSketsa.length > 0"
                    class="row mt-1"
                    style="padding: 5px"
                  >
                    <div
                      class="col-md-12"
                      v-for="(image, index) in hasilFotoSketsa"
                      :key="index"
                    >
                      <div class="image-groups">
                        <div class="image-container">
                          <img
                            :src="image.url"
                            :alt="image.name"
                            class="image-item"
                          />
                          <span>{{ image.name }}</span>
                        </div>
                        <div class="button-container">
                          <button
                            class="tombol-hapus shadow-sm"
                            @click="deletePicture(index, 2)"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <a
                href="javascript:window.history.go(-1);"
                class="btn btn-sm btn-secondary"
              >
                Kembali
              </a>
              <button
                class="btn btn-sm btn-success float-end"
                :disabled="this.loading"
              >
                <font-awesome-icon
                  v-if="loading"
                  icon="circle-notch"
                  :spin="loading"
                />
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "FormPage",
  components: {
    quillEditor,
  },
  data() {
    return {
      form: {
        jam_masuk: "",
        jam_pulang: "",
        kegiatan: "",
        deskripsi: "",
        masalah: "",
        solusi: "",
      },
      formValidate: [],
      loading: false,
      userLevel: "user",
      userToken: "",

      kameraFotoLokasi: null,
      kameraFotoSketsa: null,
      hasilFotoLokasi: [],
      hasilFotoSketsa: [],
      currentCamera: "environment",
      cameraOneOpen: false,
      cameraTwoOpen: false,
      notImage: false,

      editorStyles: {
        height: "200px",
      },
      editorStylesSmall: {
        height: "100px",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
      this.userToken = this.user.token;
    }
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("jam_masuk", this.form.jam_masuk);
      formData.append("jam_pulang", this.form.jam_pulang);
      formData.append("kegiatan", this.form.kegiatan);
      formData.append("deskripsi", this.form.deskripsi);
      formData.append("masalah", this.form.masalah);
      formData.append("solusi", this.form.solusi);
      if (this.hasilFotoSketsa.length > 0) {
        formData.append("dokumentasi", this.hasilFotoSketsa[0].url);
      }

      axios
        .post("/v1/kegiatan/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: "Sukses",
              text: "Data Berhasil Disimpan",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.stopCamera(2);
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    async toggleCamera(param) {
      await this.stopCamera(param);
      this.currentCamera =
        this.currentCamera === "environment" ? "user" : "environment";
      await this.startCamera(param);
    },
    async startCamera(param) {
      try {
        if (param == 1) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraLokasi.srcObject = stream;
          this.kameraFotoLokasi = stream;
          this.cameraOneOpen = true;
          this.stopCamera(2);
        } else {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraSketsa.srcObject = stream;
          this.kameraFotoSketsa = stream;
          this.cameraTwoOpen = true;
          this.stopCamera(1);
        }
      } catch (error) {
        console.error("Gagal membuka kamera:", error);
      }
    },
    stopCamera(param) {
      if (param == 1) {
        if (this.kameraFotoLokasi) {
          const tracks = this.kameraFotoLokasi.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraOneOpen = false;
          this.kameraFotoLokasi = null;
        }
      }
      if (param == 2) {
        if (this.kameraFotoSketsa) {
          const tracks = this.kameraFotoSketsa.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraTwoOpen = false;
          this.kameraFotoSketsa = null;
        }
      }
    },
    takePicture(param) {
      if (param === 1) {
        const video = this.$refs.kameraLokasi;
        const canvas = this.$refs.canvasLokasi;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Gambar video ke dalam canvas
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        this.hasilFotoLokasi.push({ url: imageDataURL, name: imageName });
      }
      if (param === 2) {
        const video2 = this.$refs.kameraSketsa;
        const canvas2 = this.$refs.canvasSketsa;
        canvas2.width = video2.videoWidth;
        canvas2.height = video2.videoHeight;

        // Gambar video ke dalam canvas
        const context2 = canvas2.getContext("2d");
        context2.drawImage(video2, 0, 0, video2.videoWidth, video2.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas2.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        // Memeriksa apakah objek ditemukan berdasarkan indeks
        if (this.hasilFotoSketsa.length > 0) {
          // Mengganti nilai objek dengan nilai baru
          this.hasilFotoSketsa.splice(0, 1);
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        } else {
          // Jika objek tidak ditemukan, menambahkan objek baru ke array
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        }
      }

      const audio = this.$refs.shutter;
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }

      // Pastikan ukuran canvas sesuai dengan ukuran video
    },
    deletePicture(index, param) {
      if (param == 1) {
        this.hasilFotoLokasi.splice(index, 1);
      } else {
        this.hasilFotoSketsa.splice(index, 1);
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.notImage = true;
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;

          // Mendapatkan referensi canvas
          const canvas2 = this.$refs.canvasSketsa;
          const context2 = canvas2.getContext("2d");

          // Membuat objek gambar
          const img = new Image();

          // Menetapkan sumber gambar
          img.src = event.target.result;

          // Ketika gambar sudah dimuat, gambar ke dalam canvas
          img.onload = () => {
            canvas2.width = img.width * 0.5;
            canvas2.height = img.height * 0.5;
            context2.drawImage(img, 0, 0, img.width * 0.5, img.height * 0.5);

            // Ambil data gambar dari canvas dan tambahkan ke daftar
            const imageDataURL = canvas2.toDataURL("image/png");
            const timestamp = Date.now();
            const imageName = `gambar_${timestamp}.png`;

            // Memeriksa apakah objek ditemukan berdasarkan indeks
            if (this.hasilFotoSketsa.length > 0) {
              // Mengganti nilai objek dengan nilai baru
              this.hasilFotoSketsa.splice(0, 1);
              this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
            } else {
              // Jika objek tidak ditemukan, menambahkan objek baru ke array
              this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        this.notImage = true;
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>
<style>
.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  border-radius: 3px;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.tombol-kirim {
  float: right;
  color: #fff;
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0098fe;
}
.border-danger {
  border: solid 1px #dc3545;
}
.editor-continer {
  height: 243px;
  margin-top: 7px;
}
.editor-continer-sm {
  height: 143px;
  margin-top: 7px;
}
</style>