<template>
  <nav
    class="sb-topnav navbar navbar-expand navbar-light bg-light"
    style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)"
  >
    <router-link class="navbar-brand ps-3" to="/">
      <img
        src="../assets/logo-text.png"
        alt="Logo"
        class="d-inline-block align-text-top"
        style="width: 80%"
      />
    </router-link>
    <button
      class="btn btn-link btn-light btn-sm order-1 order-lg-0 mx-2"
      id="sidebarToggle"
      @click="toggleSidebar"
    >
      <font-awesome-icon icon="bars" />
    </button>
    <ul class="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ><font-awesome-icon icon="user"
        /></a>
        <ul
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdown"
        >
          <li>
            <a class="dropdown-item">
              {{ nama }}
            </a>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <router-link
              :to="'/pengguna/password/' + this.id_users"
              class="dropdown-item"
            >
              Ganti Password
            </router-link>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a class="dropdown-item" href="javascript:;" @click="logout()">
              Logout
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "NavBar",
  data() {
    return {
      username: "",
      nama: "",
      id_users: "",
    };
  },
  created() {
    const user = localStorage.getItem("userData");
    if (user) {
      this.user = JSON.parse(user);
      this.username = this.user.username;
      this.nama = this.user.nama;
      this.id_users = this.user.id;
    }
  },
  methods: {
    logout() {
      Swal.fire({
        title: "",
        text: "Apakah Anda Akan Logout ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Logout!",
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("userData");
          if (this.$route.path !== "/login") {
            this.$router.push("/login");
          }
        }
      });
    },
    toggleSidebar() {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sidebarToggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    },
  },
};
</script>
<style scoped>
.judul {
  color: #0093dd;
  font-weight: bolder;
  border-left: solid 2px #0093dd;
  border-bottom: solid 2px #0093dd;
  text-align: center;
}
</style>