<template>
  <div class="home" style="background-color: aliceblue">
    <Header></Header>
    <div class="container" style="margin-top: -120px">
      <div class="row">
        <div class="col-md-6">
          <h2>Selamat Datang!</h2>
          <h1 class="my-2">KKLP STMIK Adhi Guna</h1>
          <h6>
            Selamat datang di Laman KKLP STMIK Adhi Guna, Yayasan Pendidikan dan
            Pembinaan Manajemen (YPPM) Adhi Guna. Laman ini diperuntukkan untuk
            mengelola data Kuliah Kerja Lapangan Profesi (KKLP) yang
            dilaksanakan oleh STMIK Adhi Guna.
          </h6>
          <router-link class="my-4 btn btn-primary me-2" to="/registrasi"
            ><font-awesome-icon icon="clipboard" /> DAFTAR</router-link
          >
          <router-link class="my-4 btn btn-warning" to="/login"
            ><font-awesome-icon icon="sign-in" /> LOGIN</router-link
          >
        </div>
        <div class="col-md-6">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
            class="img-fluid d-none d-md-block"
            alt="Sample image"
          />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  methods: {
    openForm(param) {
      // Membuat objek untuk memetakan param ke route
      const routeMap = {
        1: "formulir-1",
        2: "formulir-2",
        3: "formulir-3",
        4: "formulir-4",
      };

      if (routeMap[param]) {
        this.$router.push(routeMap[param]);
      }
    },
  },
};
</script>

<style scoped>
.home {
  min-height: 100vh;
}
.img-fluid {
  margin: auto;
  height: 75%;
}
</style>
