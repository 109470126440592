var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"card mb-4 mt-4"},[_c('div',{staticClass:"card-header bg-primary"},[_vm._v("Permohonan KTM")]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
              enabled: true,
              placeholder: 'Cari',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 9,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'dibuat')?_c('span',[_vm._v(" "+_vm._s(_vm.formatFull(props.row.created_at))+" ")]):_vm._e(),(props.column.field == 'tanggal')?_c('span',[_vm._v(" "+_vm._s(_vm.formatTanggal(props.row.tanggal_kegiatan))+" ")]):_vm._e(),(props.column.field == 'waktu')?_c('span',[_vm._v(" "+_vm._s(_vm.formatJam(props.row.jam_mulai))+" - "+_vm._s(_vm.formatJam(props.row.jam_selesai))+" ")]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"to":'/kegiatan-harian/detail/' +
                      props.row.id_kegiatan_harian}},[_c('i',{staticClass:"fa fa-search"})]),_c('router-link',{staticClass:"btn btn-sm btn-warning",staticStyle:{"margin-right":"5px"},attrs:{"to":'/kegiatan-harian/update/' +
                      props.row.id_kegiatan_harian}},[_c('i',{staticClass:"fa fa-pencil-alt"})]),_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_kegiatan_harian)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm btn-light",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-sync",class:{ 'fa-spin': _vm.loading }})]),_c('router-link',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"to":'/kegiatan-harian/create/'}},[_vm._v(" Tambah Data ")])],1)])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }