var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"card mb-4 mt-4"},[_c('div',{staticClass:"card-header bg-primary text-light"},[_vm._v("Mahasiswa")]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
              enabled: true,
              placeholder: 'Cari',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field === 'action')?[_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]:[_vm._v(" "+_vm._s(props.column.label)+" ")]]}},{key:"table-row",fn:function(props){return [(props.column.field == 'dibuat')?_c('span',[_vm._v(" "+_vm._s(_vm.formatFull(props.row.created_at))+" ")]):_vm._e(),(props.column.field == 'tanggal')?_c('span',[_vm._v(" "+_vm._s(_vm.formatTanggal(props.row.tanggal_kegiatan))+" ")]):_vm._e(),(props.column.field == 'waktu')?_c('span',[_vm._v(" "+_vm._s(_vm.formatJam(props.row.jam_mulai))+" - "+_vm._s(_vm.formatJam(props.row.jam_selesai))+" ")]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"to":'/mahasiswa/profil/' + props.row.id_mahasiswa}},[_c('font-awesome-icon',{attrs:{"icon":"search"}})],1),(_vm.userLevel == 'admin')?_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_mahasiswa)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1):_vm._e()],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm btn-secondary",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('font-awesome-icon',{attrs:{"icon":"sync","spin":_vm.loading}})],1)])])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }