import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import "./plugins/axios";
import VueGoodTable from "vue-good-table";
import "leaflet/dist/leaflet.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "quill/dist/quill.snow.css";

Vue.config.productionTip = false;

library.add(fas, far, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueGoodTable);

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
