<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6 p-4">
          <h5>KKLP</h5>
          <span>STMIK Adhi Guna</span>
        </div>
        <div class="col-md-6 p-4">
          <h5>Tentang</h5>
          <span>
            Aplikasi KKLP Adhi Guna merupakan aplikasi yang bisa di akses oleh
            seluruh mahasiswa STMIK Adhi Guna. Aplikasi ini diperuntukkan untuk
            mengelola data Kuliah Kerja Lapangan Profesi (KKLP) yang
            dilaksanakan oleh STMIK Adhi Guna.
          </span>
        </div>
      </div>
    </div>
    <div class="copy">
      Copyright &copy; 2024 by Muhammad Ikhsan | STMIK Adhi Guna Palu.
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterPage",
};
</script>
<style scoped>
.footer {
  background-color: #2b2b2b;
  color: #fff;
  position: sticky;
  top: 100%;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center; /* Mengatur teks secara vertikal di tengah */
  justify-content: center; /* Mengatur teks secara horizontal di tengah */
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
</style>