<template>
  <div style="background-color: aliceblue">
    <Header></Header>
    <main>
      <div class="p-4">
        <div
          class="row align-items-center justify-content-center"
          style="margin-top: -180px"
        >
          <div class="col-md-6 bg-white p-4 rounded-5 shadow-lg box">
            <h4 class="text-center">Formulir Pembuatan KTM</h4>
            <hr />
            <div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Nama Lengkap</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.nama_lengkap"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>NIM</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.nim"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>No.HP/WA</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.telp"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Tempat Lahir</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.tempat_lahir"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Tanggal Lahir</label>
                </div>
                <div class="col-md-8">
                  <div class="input-group mb-3">
                    <input
                      type="number"
                      v-model="form.tgl_lahir"
                      class="form-control text-center"
                      placeholder="Tanggal"
                    />
                    <select
                      class="form-control text-center"
                      v-model="form.bln_lahir"
                    >
                      <option value="">Bulan</option>
                      <option value="01">Januari</option>
                      <option value="02">Februari</option>
                      <option value="03">Maret</option>
                      <option value="04">April</option>
                      <option value="05">Mei</option>
                      <option value="06">Juni</option>
                      <option value="07">Juli</option>
                      <option value="08">Agustus</option>
                      <option value="09">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Desember</option>
                    </select>
                    <input
                      type="number"
                      v-model="form.thn_lahir"
                      class="form-control text-center"
                      placeholder="Tahun"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Progam Studi</label>
                </div>
                <div class="col-md-8">
                  <select class="form-control" v-model="form.prodi">
                    <option value="">Pilih Program Studi</option>
                    <option value="Sistem Informasi">Sistem Informasi</option>
                    <option value="Teknik Informatika">
                      Teknik Informatika
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Angkatan</label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="form.angkatan"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4">
                  <label>Foto</label>
                </div>
                <div class="col-md-8">
                  <a
                    href="#"
                    class="btn btn-block btn-primary m-1"
                    role="button"
                    @click.prevent="showFileChooser"
                  >
                    <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                  </a>
                </div>
              </div>
              <input
                style="display: none"
                ref="input"
                type="file"
                name="image"
                accept="image/*"
                @change="setImage"
              />
              <div class="row mb-3">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="2 / 3"
                      :src="imgSrc"
                      preview=".preview"
                      style="width: 100%; border-radius: 5px; overflow: hidden"
                    />
                    <div v-if="this.imgSrc">
                      <div class="btn-group mt-1">
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="rotate(90)"
                        >
                          <i class="fa fa-redo"></i>
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="rotate(-90)"
                        >
                          <i class="fa fa-redo fa-flip-horizontal"></i>
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="cropImage"
                        >
                          <i class="fa fa-crop"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                  <canvas ref="myCanvas" style="display: none"></canvas>
                  <img
                    v-if="cropImg"
                    ref="canvasImage"
                    width="100%"
                    style="border-radius: 10px"
                    alt="KTM"
                  />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <a
                    href="javascript:window.history.go(-1);"
                    class="btn btn-secondary"
                  >
                    Kembali
                  </a>
                  <button
                    @click="submitForm()"
                    class="btn btn-primary float-end"
                    type="button"
                    :disabled="this.loading"
                  >
                    <i
                      v-if="this.loading"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    Kirim
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";
import QRCode from "qrcode";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    VueCropper,
  },
  data() {
    return {
      form: {
        nama_lengkap: "",
        nim: "",
        telp: "",
        prodi: "Sistem Informasi",
        tempat_lahir: "",
        tgl_lahir: "",
        bln_lahir: "",
        thn_lahir: "",
        angkatan: "",
      },
      formValidate: [],
      loading: false,
      imgSrc: "",
      cropImg: "",
    };
  },
  // mounted() {
  //   this.drawCanvas();
  // },
  methods: {
    submitForm() {
      this.loading = true;
      const formData = new FormData();
      formData.append("longitude", this.form.longitude);
      formData.append("latitude", this.form.latitude);
      formData.append("usulan_berkala", this.form.usulan_berkala);
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("jenis_bangunan", this.form.jenis_bangunan);
      formData.append("nama_surveyor", this.form.nama_surveyor);
      formData.append("daerah_irigasi", this.form.daerah_irigasi);
      formData.append("tahun_survey", this.form.tahun_survey);
      formData.append("informasi_tambahan", this.form.informasi_tambahan);
      if (this.hasilFotoSketsa.length > 0) {
        formData.append("file_sketsa", this.hasilFotoSketsa[0].url);
      }
      if (this.hasilFotoLokasi.length > 0) {
        for (var i = 0; i < this.hasilFotoLokasi.length; i++) {
          var foto = this.hasilFotoLokasi[i];
          formData.append("foto_lokasi[]", foto.url);
        }
      }

      axios
        .post("/v1/bangunan/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: "Sukses",
              text: "Data Berhasil Dikirim",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.go(-1);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.drawCanvas();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    drawCanvas() {
      const canvas = this.$refs.myCanvas;
      const context = canvas.getContext("2d");
      const dpi = window.devicePixelRatio || 1;

      // Mengatur lebar dan tinggi canvas sesuai dengan dimensi fisik
      canvas.width = 2205 * dpi; // Lebar dalam piksel
      canvas.height = 1427 * dpi; // Tinggi dalam piksel

      context.imageSmoothingQuality = "high";

      // Gambar latar belakang
      context.fillStyle = "#f0f0f0";
      context.fillRect(0, 0, canvas.width, canvas.height);

      const blanko = new Image();
      blanko.src = require("@/assets/blanko.png");

      blanko.onload = () => {
        context.drawImage(blanko, 0, 0, canvas.width, canvas.height);

        const foto = new Image();
        foto.src = this.cropImg;

        foto.onload = () => {
          // Gambar latar belakang dengan lengkungan di setiap sudut
          this.drawRoundedRect(
            context,
            140 * dpi,
            580 * dpi,
            435 * dpi,
            640 * dpi,
            50 * dpi
          );

          // Hentikan penggunaan clip agar tidak membatasi konteks gambar di luar foto.onload
          context.clip("nonzero");

          // Menggambar gambar ke dalam canvas
          context.drawImage(foto, 140 * dpi, 580 * dpi, 435 * dpi, 640 * dpi);

          // Mendapatkan data piksel untuk piksel sudut kanan atas
          const pixelData = context.getImageData(
            200 * dpi,
            600 * dpi,
            1,
            1
          ).data;

          const redThreshold = 150;
          const greenThreshold = 150;

          // Memeriksa apakah komponen merah lebih tinggi daripada ambang batas
          const isRed =
            pixelData[0] > redThreshold && // Komponen merah
            pixelData[1] < redThreshold / 2 && // Komponen hijau (dapat disesuaikan)
            pixelData[2] < redThreshold / 2; // Komponen biru (dapat disesuaikan)

          const isYellow =
            pixelData[1] > greenThreshold && // Komponen hijau (tinggi)
            pixelData[0] < redThreshold / 2; // Komponen merah (tidak terlalu tinggi)

          if (isRed) {
            console.log("Warna pada sudut kanan atas adalah merah.");
          } else if (isYellow) {
            console.log("Warna pada sudut kanan atas adalah kuning.");
          } else {
            console.log("Warna pada sudut kanan atas bukan merah atau kuning.");
          }

          const redComponent = pixelData[0]; // Komponen merah
          const greenComponent = pixelData[1]; // Komponen hijau
          const blueComponent = pixelData[2]; // Komponen biru

          console.log(
            `Nilai warna RGB pada sudut kanan atas: (${redComponent}, ${greenComponent}, ${blueComponent})`
          );

          const canvasImage = this.$refs.canvasImage;

          const dataURL = canvas.toDataURL("image/png");

          canvasImage.src = dataURL;
        };

        // Menghasilkan Promise untuk QR code data URL
        const qrCodePromise = QRCode.toDataURL("https://stmikadhiguna.ac.id");

        // Menunggu hingga Promise selesai
        qrCodePromise
          .then((qrCodeUrl) => {
            // Membuat objek Image
            const qrCodeImage = new Image();
            qrCodeImage.src = qrCodeUrl;

            // Menunggu hingga gambar QR code selesai dimuat
            qrCodeImage.onload = () => {
              // Menggambar gambar ke dalam canvas setelah QR code selesai dimuat
              context.drawImage(
                qrCodeImage,
                800 * dpi,
                580 * dpi,
                435 * dpi,
                640 * dpi
              );
            };
          })
          .catch((error) => {
            console.error("Gagal menghasilkan QR code:", error);
          });

        context.font = `bold ${100 * dpi}px Saira`;
        context.fillStyle = "#274665";
        context.fillText(this.form.nama_lengkap, 620 * dpi, 800 * dpi);

        context.font = `bold ${100 * dpi}px Saira`;
        context.fillStyle = "#274665";
        context.fillText(this.form.nim, 620 * dpi, 950 * dpi);

        context.font = `bold ${100 * dpi}px Saira`;
        context.fillStyle = "#274665";
        context.fillText(this.form.prodi, 620 * dpi, 1100 * dpi);
      };
    },

    drawRoundedRect(context, x, y, width, height, radius) {
      context.beginPath();
      context.moveTo(x + radius, y);
      context.arcTo(x + width, y, x + width, y + height, radius);
      context.arcTo(x + width, y + height, x, y + height, radius);
      context.arcTo(x, y + height, x, y, radius);
      context.arcTo(x, y, x + width, y, radius);
      context.closePath();
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Saira";
  src: url("@/assets/saira.ttf") format("truetype");
}
.no-link {
  text-decoration: none;
}
.box {
  border-top: solid 5px #2968a0;
  border-bottom: solid 5px #0099ff;
}
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
